import React from "react";

const TextInput = ({ placeholder, name, id, value, onChange, className }) => {
  return (
    <div className="w-full">
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className={`rounded-md md:rounded-[0.5vw] px-3 md:px-[0.7vw] py-2 md:py-[0.6vw] times-new-roman text-[15px] md:text-[1vw] bg-[#ffffff2a] w-full outline-none focus:ring-1 focus:ring-[#ffffff] ${className}`}
      />
    </div>
  );
};

export default TextInput;
