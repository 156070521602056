import React from "react";
import { ImagesPath } from "../helper/ImagePath";

const BannerLine = ({ gradient , color = '#20A49B', rotate = false }) => {
  return (
    <div className={`w-full flex gap-1 md:gap-[0.5vw] items-end ${rotate ? 'scale-y-[-1]' : ''}`}>
      <div className="w-[50px] md:w-[5.5vw] ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 95.194 53.64"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            id="tool-design"
            d="M18.706-255.958c-3.641,0-7.781,3.473-7.781,8.361,0,3.89,3.064,8.525,10.432,8.525,7.616,0,14.9-5.051,14.9-16.058,0-12-11.423-21.026-22.516-21.026-9.435,0-24.668,7.449-24.668,24.172,0,13.9,9.936,22.019,24.339,26.817l-.167.167c-21.522-2.818-35.1-15.4-35.1-31.206,0-5.384,1.905-11.012,5.712-16.971l.331-.5h-1.074c-12.582,0-18.376,9.438-18.376,16.474,0,8.526,10.429,24.336,29.719,30.792l4.218,1.241V-225h-.5c-30.793,0-45.692-16.557-45.692-32.78,0-1.657.165-2.813.248-4.223-5.876,1.905-11.671,6.953-11.671,13.575,0,9.518,16.225,25.911,54.964,25.911h40.23V-235.6l-.415.418a24.921,24.921,0,0,1-16.8,7.2c-13.989,0-26.488-10.594-26.488-24.419a20.7,20.7,0,0,1,20.614-20.778c11.174,0,19.616,7.949,19.616,18.047,0,6.783-3.806,10.1-5.877,10.1-.413,0-.744-.249-.744-1.241,0-.5.165-.664.165-1.742A7.872,7.872,0,0,0,18.706-255.958Z"
            transform="translate(58.938 276.156)"
            fill={color}
          />
        </svg>
      </div>
      <div className={`flex-1 w-full h-[7px] md:h-[0.8vw] ${gradient}`}></div>
      <div className="w-[50px] md:w-[5.5vw] scale-x-[-1]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 95.194 53.64"
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            id="tool-design"
            d="M18.706-255.958c-3.641,0-7.781,3.473-7.781,8.361,0,3.89,3.064,8.525,10.432,8.525,7.616,0,14.9-5.051,14.9-16.058,0-12-11.423-21.026-22.516-21.026-9.435,0-24.668,7.449-24.668,24.172,0,13.9,9.936,22.019,24.339,26.817l-.167.167c-21.522-2.818-35.1-15.4-35.1-31.206,0-5.384,1.905-11.012,5.712-16.971l.331-.5h-1.074c-12.582,0-18.376,9.438-18.376,16.474,0,8.526,10.429,24.336,29.719,30.792l4.218,1.241V-225h-.5c-30.793,0-45.692-16.557-45.692-32.78,0-1.657.165-2.813.248-4.223-5.876,1.905-11.671,6.953-11.671,13.575,0,9.518,16.225,25.911,54.964,25.911h40.23V-235.6l-.415.418a24.921,24.921,0,0,1-16.8,7.2c-13.989,0-26.488-10.594-26.488-24.419a20.7,20.7,0,0,1,20.614-20.778c11.174,0,19.616,7.949,19.616,18.047,0,6.783-3.806,10.1-5.877,10.1-.413,0-.744-.249-.744-1.241,0-.5.165-.664.165-1.742A7.872,7.872,0,0,0,18.706-255.958Z"
            transform="translate(58.938 276.156)"
            fill={color}
          />
        </svg>
      </div>
    </div>
  );
};

export default BannerLine;
