import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ImagesPath } from "../../helper/ImagePath";
import { toast } from "react-toastify";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const ReviewsView = ({ novel, onClose, reviews, setReviews }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(reviews, "REVIEWS");
  }, [reviews]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-all-reviews/${novel.id}`)
      .then((res) => {
        console.log(res, "RES OF REVIEW Get All Reviews Successfully");
        if (setReviews) {
          setReviews((prev) => ({ ...prev, [novel.id]: res.data }));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "RES OF REVIEW Get All Reviews Error");
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full h-dvh bg-black/70 backdrop-blur-sm flex justify-center items-center fixed top-0 left-0 z-50">
      <div className="max-w-[850px] relative w-[90%] min-h-[100px] max-h-[80dvh] h-fit bg-gradient-mn rounded-lg md:rounded-[0.8vw] p-2 md:p-[1.5vw] flex flex-col gap-4 items-center justify-center">
        <div
          className="absolute top-[-10px] right-[-10px] md:right-[-0.6vw] md:top-[-0.6vw] w-[20px] md:w-[1.2vw] h-[20px] md:h-[1.2vw] cursor-pointer flex items-center justify-center bg-red-500 border border-white rounded-full"
          onClick={onClose}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="text-white text-[12px] md:text-[0.8vw]"
          />
        </div>
        <div className="w-full h-full flex flex-col gap-2 md:gap-4 overflow-y-auto pr-1 custom-scrollbar">
          {reviews && Array.isArray(reviews) && reviews.length > 0 ? (
            reviews.map((review) => {
              return (
                <div
                  key={review.id} // Add a unique key for each review
                  className="w-full relative justify-between h-auto bg-[#ffffff2a] rounded-lg md:rounded-[0.8vw] p-2 md:p-4 flex gap-2 md:gap-4"
                >
                  <div className="flex items-start gap-2 w-full">
                    <div className="min-w-[40px] min-h-[40px] md:min-w-[8vh] md:min-h-[8vh] text-[20px] md:text-[1.5vw] font-bold text-white rounded-full bg-[#a7a7a7] border-2 flex items-center justify-center flex-shrink-0">
                      {review.name && review.name.charAt(0).toUpperCase()}
                    </div>
                    
                    <div className="flex-1 min-w-0">
                      <div className="flex items-center gap-1 md:gap-[1vw]">
                        <p className="text-white text-[12px] font-bold md:text-[0.9vw] truncate">
                          {review.name}
                        </p>
               
                        <div className="flex items-center gap-[2px] md:gap-[0.3vw]">
                          {[1, 2, 3, 4, 5].map((item) => (
                            <div
                              key={item}
                              className="w-[12px] md:w-[0.8vw] h-[12px] md:h-[0.8vw]"
                            >
                              <img
                                src={
                                  review.rating >= item
                                    ? ImagesPath.startYellow
                                    : ImagesPath.startBorder
                                }
                                alt="star"
                                className="w-full h-full"
                              />
                            </div>
                          ))}
                          <div className="text-[#D2C7D2] h-auto text-[12px] md:text-[0.8vw]">
                            {review.rating}
                          </div>
                        </div>
                      </div>

                  {review.address && (
  <p className="text-white text-[12px] font-bold md:text-[0.9vw] truncate flex gap-1">
    <FontAwesomeIcon icon={faLocationDot} /> {review.address}
  </p>
)}

                      <p className="text-white text-[11px] md:text-[1vw] 2xl:text-[0.9vw] break-words">
                        {review.message}
                      </p>
                    </div>
                  </div>
                  {/* <div className="text-white absolute right-2 top-2 md:right-4 md:top-4 text-[11px] md:text-[0.7vw] whitespace-nowrap">
                    {new Date(review.createdAt).toLocaleString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div> */}
                </div>
              );
            })
          ) : Array.isArray(reviews) && reviews.length === 0 && !loading ? (
            <div className="text-white text-[15px] md:text-[0.9vw] w-full h-full flex gap-2 md:gap-[0.5vw] items-center justify-center">
              No reviews found
            </div>
          ) : (
            <div className="text-white text-[15px] md:text-[0.9vw] w-full h-full flex gap-2 md:gap-[0.5vw] items-center justify-center">
              <div className="w-[15px] h-[15px] md:w-[1vw] md:h-[1vw] rounded-full flex items-center justify-center">
                <CircularProgress size={20} color="white" />
              </div>
              Loading reviews...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewsView;