export const ImagesPath = {
  novel1: require("../assets/NovelImages/novel1.png"),
  novel2: require("../assets/NovelImages/novel2.png"),
  novel3: require("../assets/NovelImages/novel3.png"),
  novel4: require("../assets/NovelImages/novel4.png"),
  novel5: require("../assets/NovelImages/novel5.png"),
  novel6: require("../assets/NovelImages/novel6.png"),
  borderTip: require("../assets/NovelImages/banner-design-tip.png"),
  toolDesign: require("../assets/NovelImages/tool-design.svg"),
  startYellow: require("../assets/NovelImages/ratingstart-yellow.png"),
  startBorder: require("../assets/NovelImages/ratingstart-simple.png"),
  writeReview01Btn: require("../assets/img/write-review-01.png"),
  novelBtnRed: require("../assets/NovelImages/novel2-btn.png"),
  novelBtnRedDark: require("../assets/NovelImages/novel-btn-red.png"),
  novelBtnBlue: require("../assets/NovelImages/novel-btn-blue.png"),
  novelBtnGreen: require("../assets/NovelImages/novel-btn-green.png"),
  novelBtnLGreen: require("../assets/NovelImages/novel-btn-lgreen.png"),
};
