import React, { useState } from "react";
import { Link } from "react-router-dom";
import WriteReviewModal from "./Modals/WriteReviewModal";
import ReviewsView from "./Modals/ReviewsView";
import BannerLine from "./BannerLine";
import { ImagesPath } from "../helper/ImagePath";

const NovelCard = ({
  novel,
  onModalOpen,
  reviews,
  setReviews,
  setAddReview,
}) => {
  const [showWriteReviewModal, setShowWriteReviewModal] = useState(false);
  const [showReviewsViewModal, setShowReviewsViewModal] = useState(false);
  return (
    <>
      {showWriteReviewModal && (
        <WriteReviewModal
          novel={novel}
          setAddReview={setAddReview}
          onClose={() => setShowWriteReviewModal(false)}
        />
      )}
      {showReviewsViewModal && (
        <ReviewsView
          novel={novel}
          onClose={() => setShowReviewsViewModal(false)}
          reviews={reviews[novel.id]}
          setReviews={setReviews}
        />
      )}
      <div className={`w-full md:w-[90%] mx-auto mb-10 md:mb-20 ${novel.className}`}>
        <img src={novel.image} alt={novel.title} className="w-full" />
        <div className="flex flex-col items-center justify-center ">
          <BannerLine
            gradient={novel.novelLineGradient}
            color={novel.novelLineColor}
          />
          <div
            className="flex-1 flex-col flex py-2 md:py-[0.5vw] items-center justify-center gap-2 md:gap-[0.5vw]  w-[calc(100%-112px)] md:w-[calc(100%-13vw)] shadow-inner shadow-black min-h-[150px]"
            style={{
              backgroundColor: "#212121",
            }}
          >
            <h2
              className="text-[13px] md:text-[2vw] font-bold times-new-roman max-w-[65%] text-center"
              style={{ color: novel.novelLineColor }}
            >
              {novel.title}
            </h2>
            <div className="flex items-center gap-4 md:gap-[0.7vw]">
              <div className="flex items-center gap-2 md:gap-[0.4vw]">
                {[1, 2, 3, 4, 5].map((item) => (
                  <div
                    key={item}
                    className="w-[15px] md:w-[1vw] h-[15px] md:h-[1vw]"
                  >
                    <img
                      src={
                        novel.rating >= item
                          ? ImagesPath.startYellow
                          : ImagesPath.startBorder
                      }
                      alt="star"
                      className={`w-full h-full`}
                    />
                  </div>
                ))}
                <div className="text-[#D2C7D2] text-[12px] md:text-[1vw]">
                  {novel.rating}
                </div>
              </div>
              <div
                className="text-[#D2C7D2] underline lowercase hover:text-white transition-all duration-300 text-[12px] md:text-[1vw] cursor-pointer"
                onClick={() => setShowReviewsViewModal(novel.id)}
              >
                View All
              </div>
            </div>
            <p className="text-[#D2C7D2] text-[12px] text-center md:text-[0.9vw]">
              Published by admin on September 15. 2021
            </p>
            <p className="text-[#D2C7D2] text-[11px] text-center px-3 md:px-[0.5vw] md:text-[0.8vw]">
              {novel.shortDescription}
            </p>
          </div>
          <BannerLine
            gradient={novel.novelLineGradient}
            color={novel.novelLineColor}
            rotate={true}
          />
        </div>
        <div className={`btn-main ${novel.className || ""}`}>
          <a href={novel.pdf} download>
            Read Novel
          </a>
          <Link>Or</Link>
          <Link onClick={() => onModalOpen(novel.modalId)}>Listen Novel</Link>
        </div>
        <div
          className={` writereview-btn cursor-pointer image-btn-hover mx-auto flex items-center gap-2 relative`}
          onClick={() => setShowWriteReviewModal(true)}
        >
          <img
            src={novel.writeReviewBtnImage}
            alt="write-review"
            className=""
          />
          <p className="text-white text-[13px] whitespace-nowrap md:text-[0.8vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
            Write Review
          </p>
        </div>
      </div>
    </>
  );
};

export default NovelCard;
