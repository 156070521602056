import React, { useState } from "react";
import TextInput from "../TextInput";
import TextAreaInput from "../TextAreaInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ImagesPath } from "../../helper/ImagePath";
import axios from "axios";
import { toast } from "react-toastify";

const WriteReviewModal = ({ onClose, novel , setAddReview }) => {
  const [review, setReview] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rating, setRating] = useState(0);
  const [address, setAddress] = useState("");
  const handleRating = (rating) => {
    setRating(rating);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function validateEmail(email) {
    return emailRegex.test(email);
  }

  const handleSubmit = () => {
    console.log(review, name, email, rating);
    if (review === "" || name === "" || email === "") {
      toast.error("Please fill in all fields");
      return;
    }
    if (rating === 0) {
      toast.error("Please select a rating");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email");
      return;
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/add-review`, {
        message: review,
        name : name,
        email : email,
        address : address,
        rating : rating,
        productId: novel.id,
      })
      .then((res) => {
        console.log(res, "RES OF REVIEW Successfully Submitted");
        toast.success("Review Successfully Submitted");
        onClose();
        if(setAddReview){
          setAddReview(true);
        }
      })
      .catch((err) => {
        console.log(err, "RES OF REVIEW Error");
        toast.error("Review Submission Failed");
      });
  };

  return (
    <div className="w-full h-dvh bg-black/70 backdrop-blur-sm flex justify-center items-center fixed top-0 left-0 z-50">
      <div className="max-w-[700px] relative w-[90%] min-h-[500px] h-fit bg-gradient-mn rounded-lg md:rounded-[0.8vw] p-4 flex flex-col gap-4 items-center justify-center">
        <div
          className="absolute top-[-10px] right-[-10px] w-[20px] md:w-[1.2vw] h-[20px] md:h-[1.2vw] cursor-pointer flex items-center justify-center bg-red-500 border border-white rounded-full"
          onClick={onClose}
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="text-white text-[12px] md:text-[0.8vw]"
          />
        </div>
        <h3 className="text-white times-new-roman text-[25px] md:text-[2.1vw] font-bold">
          Write a review
        </h3>
        <div className="flex items-center gap-2">
          {[1, 2, 3, 4, 5].map((item) => (
            <FontAwesomeIcon
              icon={faStar}
              className={`text-white times-new-roman cursor-pointer text-[17px] md:text-[1.4vw] ${
                rating >= item ? "text-yellow-500" : ""
              }`}
              onClick={() => handleRating(item)}
            />
          ))}
        </div>
        <TextInput
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="placeholder:text-[#CECECE] text-white"
        />
        <TextInput
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="placeholder:text-[#CECECE] text-white"
        />
                <TextInput
          placeholder="Location"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="placeholder:text-[#CECECE] text-white"
        />
        <TextAreaInput
          placeholder="Write your review here"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          className="h-[150px] md:h-[9vw] resize-none text-white placeholder:text-[#CECECE]"
        />

        <div
          className={` writereview-btn cursor-pointer image-btn-hover mx-auto flex items-center gap-2 relative`}
          onClick={handleSubmit}
        >
          <img
            src={ImagesPath.writeReview01Btn}
            alt="write-review"
            className=""
          />
          <p className="text-white text-[13px] whitespace-nowrap md:text-[0.8vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
            Submit
          </p>
        </div>
      </div>
    </div>
  );
};

export default WriteReviewModal;
