import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEnvelope,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import sec1Img from "../assets/img/sec1.png";
import sec2Img from "../assets/img/sec2.png";
import sec3Img from "../assets/img/sec3.png";
import sec4Img from "../assets/img/sec4.png";
import sec5Img from "../assets/img/sec5.png";
import sec6Img from "../assets/img/sec6.png";
import audioLiyar from "../assets/img/audio-liyar.png";
import pdf1 from "../assets/pdf/SPARTAN-GAMES.pdf";
import pdf2 from "../assets/pdf/WE-WONT-DIE-WONDERING.pdf";
import pdf3 from "../assets/pdf/Roman-Death-of-a-Greek-Slave.pdf";
import pdf4 from "../assets/pdf/A-CATTLESTATION-FAGGOT-1.pdf";
import pdf5 from "../assets/pdf/Dan-Ericson-2-2.pdf";
import pdf6 from "../assets/pdf/MUTILATIONS-1.pdf";
import modalImg1 from "../assets/img/modal-1.png";
import modalImg2 from "../assets/img/red-modalpic.png";
import modalImg3 from "../assets/img/spartman-games.png";
import modalImg4 from "../assets/img/modal4-img.png";
import modalImg5 from "../assets/img/modal5-img.png";
import modalImg6 from "../assets/img/modal6-img.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import NovelCard from "../components/NovelCard";
import { ImagesPath } from "../helper/ImagePath";

const audio1Male = "https://cdn.techciaga.com.au/freenovel/audio1-male.mp3";
const audio1Female = "https://cdn.techciaga.com.au/freenovel/audio1-female.mp3";
const audio2Male = "https://cdn.techciaga.com.au/freenovel/audio2-male.mp3";
const audio2Female = "https://cdn.techciaga.com.au/freenovel/audio2-female.mp3";
const audio3 = "https://cdn.techciaga.com.au/freenovel/audio3.mp3";
const audio4 = "https://cdn.techciaga.com.au/freenovel/audio4.mp3";
const audio5 = "https://cdn.techciaga.com.au/freenovel/audio5.mp3";
const audio6 = "https://cdn.techciaga.com.au/freenovel/audio6.mp3";
const LandingPage = () => {
  const audioRefs = useRef(
    Array(8)
      .fill(null)
      .map(() => React.createRef())
  );
  const audioSources = [
    audio1Male,
    audio1Female,
    audio2Male,
    audio2Female,
    audio3,
    audio4,
    audio5,
    audio6,
  ];
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleDownload = async (index) => {
    const audioSrc = audioSources[index];
    const progressBar = document.getElementById("progressBar");
    progressBar.style.display = "block";
    const response = await fetch(audioSrc);
    const contentLength = response.headers.get("Content-Length");
    const totalSize = parseInt(contentLength, 10);
    let downloaded = 0;

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
      downloaded += value.length;
      const progress = (downloaded / totalSize) * 100;
      setDownloadProgress(progress);
    }
    progressBar.style.display = "none";
    const blob = new Blob(chunks);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `audio${index + 1}.mp3`;
    link.click();
    document.body.appendChild(link);
    document.body.removeChild(link);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [addReview, setAddReview] = useState(false);

  const [reviews, setReviews] = useState({});
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const openModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const openModal3 = () => {
    setIsOpen3(!isOpen3);
  };
  const openModal4 = () => {
    setIsOpen4(!isOpen4);
  };
  const openModal5 = () => {
    setIsOpen5(!isOpen5);
  };
  const openModal6 = () => {
    setIsOpen6(!isOpen6);
  };
  const openModal7 = () => {
    setIsOpen7(!isOpen7);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name.trim() || !email.trim() || !message.trim()) {
      toast.error("Please fill in all fields");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact`,
        {
          name,
          email,
          message,
        }
      );
      toast.success("Form submitted successfully!");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      toast.error(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [novelsData, setNovelsData] = useState([
    {
      id: "8f7d9a62-3b1e-4e8a-9c5d-2f8b7a3e4d1c",
      title: "The Aussie's Spartan-Games",
      image: ImagesPath.novel1,
      pdf: pdf1,
      className: "",
      buttonClassName: "spartman-btn-main",
      writeReviewBtnClassName: "write-review-btn-spartman",
      modalId: 1,
      novelLineGradient: "novel-line-gradient",
      novelLineColor: "#20A49B",
      rating: 0,
      shortDescription:
        "The Aussie's Spartan-Games Novel One Of The Perfect-S Novel-Series by Sean McFrinn",
      writeReviewBtnImage: ImagesPath.writeReview01Btn,
    },
    {
      id: "2c4a6b8d-5e7f-4a9b-8c1d-3e5f7a9b2c4d",
      title: "We Won't Die Wondering",
      image: ImagesPath.novel2,
      pdf: pdf2,
      className: "sec2",
      modalId: 2,
      writeReviewBtnClassName: "write-review-btn-spartman",
      novelLineGradient: "novel2-line-gradient",
      novelLineColor: "#A42020",
      rating: 0,
      shortDescription:
        "We Won't Die Wondering Novel One Of The Perfect-S Novel-Series by Sean McFirnn",
      writeReviewBtnImage: ImagesPath.novelBtnRed,
    },
    {
      id: "1a3b5c7d-9e2f-4b6d-8a0c-2e4f6g8h0j2k",
      title: "The Roman-Death Of A Greek-Slave",
      image: ImagesPath.novel3,
      pdf: pdf3,
      className: "sec3",
      modalId: 3,
      writeReviewBtnClassName: "write-review-btn-spartman",
      novelLineGradient: "novel3-line-gradient",
      novelLineColor: "#A42695",
      rating: 0,
      shortDescription:
        "The Aussie's Spartan-Games Novel One Of The Perfect-S Novel-Series by Sean McFrinn",
      writeReviewBtnImage: ImagesPath.novelBtnBlue,
    },
    {
      id: "5d7e9f2a-4b6c-8d0e-2f4g-6h8j0k2l4m6n",
      title: "A Cattle-Station Faggot",
      image: ImagesPath.novel4,
      pdf: pdf4,
      className: "sec4",
      modalId: 4,
      writeReviewBtnClassName: "write-review-btn-spartman",
      novelLineGradient: "novel4-line-gradient",
      novelLineColor: "#A47F34",
      rating: 0,
      shortDescription:
        "The Aussie's Spartan-Games Novel One Of The Perfect-S Novel-Series by Sean McFrinn",
      writeReviewBtnImage: ImagesPath.novelBtnLGreen,
    },
    {
      id: "3f5g7h9j-2k4l-6m8n-0p2q-4r6s8t0u2v4w",
      title: "Dan Ericson: Norwegain Cop",
      image: ImagesPath.novel5,
      pdf: pdf5,
      className: "sec5",
      modalId: 5,
      writeReviewBtnClassName: "write-review-btn-spartman",
      novelLineGradient: "novel5-line-gradient",
      novelLineColor: "#570A0C",
      rating: 0,
      shortDescription:
        "Dan Ericson: Norwegain Cop Novel Five Of The Perfect-S Novel-Series by Sean McFirnn",
      writeReviewBtnImage: ImagesPath.novelBtnRedDark,
    },
    {
      id: "7k9l2m4n-6p8q-0r2s-4t6u-8v0w2x4y6z8a",
      title: "Mutilations",
      shortDescription:
        "Dan Ericson: Norwegain Cop Novel Five Of The Perfect-S Novel-Series by Sean McFirnn",
      image: ImagesPath.novel6,
      pdf: pdf6,
      className: "sec6",
      modalId: 6,
      writeReviewBtnClassName: "write-review-btn-spartman",
      novelLineGradient: "novel6-line-gradient",
      novelLineColor: "#574510",
      rating: 0,
      writeReviewBtnImage: ImagesPath.novelBtnGreen,
    },
  ]);

  const GetRatings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/with-average-rating`
      );
      if (response.data) {
        const updatedNovels = novelsData.map((novel) => {
          const ratingData = response.data.find(
            (item) => item.productId === novel.id
          );
          return {
            ...novel,
            rating: ratingData ? ratingData.averageRating : novel.rating,
          };
        });
        setNovelsData(updatedNovels);
        setAddReview(false);
      }
    } catch (error) {
      console.error("Error fetching ratings:", error);
    }
  };

  useEffect(() => {
    GetRatings();
  }, []);

  useEffect(() => {
    if (addReview) {
      GetRatings();
    }
  }, [addReview]);

  useEffect(() => {
    console.log(novelsData);
  }, [novelsData]);

  const handleModalOpen = (modalId) => {
    switch (modalId) {
      case 1:
        setIsOpen(true);
        break;
      case 2:
        setIsOpen2(true);
        break;
      case 3:
        setIsOpen3(true);
        break;
      case 4:
        setIsOpen4(true);
        break;
      case 5:
        setIsOpen5(true);
        break;
      case 6:
        setIsOpen6(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="main">
        <div
          style={{
            position: "absolute",
            zIndex: 9999,
            margin: 20,
            top: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          {downloadProgress.toFixed() > 0 &&
            downloadProgress.toFixed() < 100 && (
              <h3
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {downloadProgress.toFixed()}%
              </h3>
            )}
          <progress
            id="progressBar"
            value={downloadProgress}
            max="100"
            style={{ height: "10px", display: "none" }}
          />
        </div>
        <div
          className={`w-full md:w-[25%] bg-[#27292b] p-3 md:p-5 fixed top-0 left-0 min-h-full overflow-y-auto z-50 md:relative ${
            isSidebarOpen
              ? "translate-x-0 md:translate-x-0"
              : "-translate-x-full md:translate-x-0"
          } transition-transform duration-300`}
        >
          <div className="Left-side-Content-inner relative">
            <div className="w-full md:hidden absolute top-0 left-0 py-2 px-3 flex items-center justify-end">
              <FontAwesomeIcon
                icon={faXmark}
                className="text-white text-2xl cursor-pointer"
                onClick={toggleSidebar}
              />
            </div>
            <div className="Content-Novels">
              <h4>
                Free Novels by Sean <br /> McFirnn
              </h4>
              <p>Gay Romance Novels</p>
              <Link to="mailto:info@freenovelsbyseanmcfirnn.com">
                <FontAwesomeIcon icon={faEnvelope} />
              </Link>
            </div>
            <div className="Content-Nav">
              <Link
                onClick={() => {
                  setIsSidebarOpen(false);
                }}
              >
                Home
              </Link>
              <Link
                onClick={() => {
                  openModal7();
                  setIsSidebarOpen(false);
                }}
              >
                Contact
              </Link>
            </div>
            <div className="Author-info">
              <h5>Author Bio</h5>
              <p>
                Sean McFirnn was born in 1942 outside of Marble Bar, West
                Australia. He and his Best-Mate are both retired. They divide
                their year between Queensland and Hawaii. Please note: Gareth is
                far more interesting than Sean. Free Novels By Sean McFirnn
                Beyond BDSM experience and a maso who became a Best-Mate of over
                a quarter-century, the hero and his author have little in
                common. Sean McFirnn welcomes your comments in his blog's chat
                box:{" "}
                <a
                  href="mailto:rainforestpervertsado@bdsmlr.com"
                  className="text-gray-300 hover:underline "
                >
                  rainforestpervertsado@bdsmlr.com
                </a>{" "}
                If you have problem with the way He wrote them, He suggests that
                you should write your own porn-novel. Moreover, we have some
                great novel for the community to read such as- Free Novels By
                Sean McFirnn gay romance novels by sean McFirnn bdsm novels by
                sean McFirnn Sean does welcome corrections of terminology,
                chronology, or techniques. He would appreciate your pointing out
                actual typos.
              </p>
            </div>
          </div>
        </div>
        <div className="right-side-Content w-full md:w-[75%]">
          <div className="w-full md:hidden fixed top-0 left-0 border-b h-[50px] bg-black z-10 px-3 flex items-center justify-between">
            <p className="text-white text-[15px] font-bold">
              Free Novels by Sean McFirnn
            </p>
            <FontAwesomeIcon
              icon={faBars}
              className="text-white text-2xl cursor-pointer"
              onClick={toggleSidebar}
            />
          </div>
          <div className="w-full md:w-[90%] mx-auto p-1 md:p-0 mt-[60px]">
            {novelsData.map((novel, index) => (
              <NovelCard
                key={novel.id}
                novel={novel}
                onModalOpen={handleModalOpen}
                reviews={reviews}
                setReviews={setReviews}
                setAddReview={setAddReview}
              />
            ))}
          </div>
        </div>
        {isOpen && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content">
              <div className="modal-content-inner">
                <img src={modalImg1} alt="" />
              </div>
              <h3>The Aussie's Spartan-Games</h3>
              <p>Novel One of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <div className="audio-liyar2-main">
                <div className="audio-liyar2">
                  <h3>Male</h3>
                  <AudioPlayer
                    ref={audioRefs.current[0]}
                    src={audio1Male}
                    className="audio-player"
                  />
                  <button
                    style={{
                      opacity:
                        downloadProgress.toFixed() > 0 &&
                        downloadProgress.toFixed() < 100
                          ? 0.5
                          : 1,
                    }}
                    disabled={
                      downloadProgress.toFixed() > 0 &&
                      downloadProgress.toFixed() < 100
                    }
                    className="btn-download"
                    onClick={() => handleDownload(0)}
                  >
                    Download
                  </button>
                </div>
                <div className="audio-liyar2">
                  <h3>Female</h3>
                  <AudioPlayer
                    ref={audioRefs.current[1]}
                    src={audio1Female}
                    className="audio-player"
                  />
                  <button
                    style={{
                      opacity:
                        downloadProgress.toFixed() > 0 &&
                        downloadProgress.toFixed() < 100
                          ? 0.5
                          : 1,
                    }}
                    disabled={
                      downloadProgress.toFixed() > 0 &&
                      downloadProgress.toFixed() < 100
                    }
                    className="btn-download"
                    onClick={() => handleDownload(1)}
                  >
                    Download
                  </button>
                </div>
              </div>
              <FontAwesomeIcon
                onClick={openModal}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen2 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content modal-content2">
              <div className="modal-content-inner">
                <img src={modalImg2} alt="" />
              </div>
              <h3>We Won't Die Wondering</h3>
              <p>Novel Three of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              {/* <p className="modal-content-sec-para">
                We Won't Die Wondering Novel One Of The Perfect-S Novel-Series by Sean McFirnn
              </p> */}
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <div className="audio-liyar2-main">
                <div className="audio-liyar2">
                  <AudioPlayer
                    ref={audioRefs.current[2]}
                    src={audio2Male}
                    className="audio-player"
                  />
                  <button
                    style={{
                      opacity:
                        downloadProgress.toFixed() > 0 &&
                        downloadProgress.toFixed() < 100
                          ? 0.5
                          : 1,
                    }}
                    disabled={
                      downloadProgress.toFixed() > 0 &&
                      downloadProgress.toFixed() < 100
                    }
                    className="btn-download"
                    onClick={() => handleDownload(2)}
                  >
                    Download
                  </button>
                </div>
                <div className="audio-liyar2">
                  <AudioPlayer
                    ref={audioRefs.current[3]}
                    src={audio2Female}
                    className="audio-player"
                  />
                  <button
                    style={{
                      opacity:
                        downloadProgress.toFixed() > 0 &&
                        downloadProgress.toFixed() < 100
                          ? 0.5
                          : 1,
                    }}
                    disabled={
                      downloadProgress.toFixed() > 0 &&
                      downloadProgress.toFixed() < 100
                    }
                    className="btn-download"
                    onClick={() => handleDownload(3)}
                  >
                    Download
                  </button>
                </div>
              </div>
              <FontAwesomeIcon
                onClick={openModal2}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen3 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content modal-content2">
              <div className="modal-content-inner">
                <img src={modalImg3} alt="" />
              </div>
              <h3>The Roman-Death Of A Greek-Slave</h3>
              <p>Novel Three of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              {/* <p className="modal-content-sec-para">
                The Aussie's Spartan-Games Novel One Of The Perfect-S Novel-Series by Sean McFirnn
              </p> */}
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <AudioPlayer
                ref={audioRefs.current[4]}
                src={audio3}
                className="audio-player"
              />
              <button
                style={{
                  opacity:
                    downloadProgress.toFixed() > 0 &&
                    downloadProgress.toFixed() < 100
                      ? 0.5
                      : 1,
                }}
                disabled={
                  downloadProgress.toFixed() > 0 &&
                  downloadProgress.toFixed() < 100
                }
                className="btn-download"
                onClick={() => handleDownload(4)}
              >
                Download
              </button>
              <FontAwesomeIcon
                onClick={openModal3}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen4 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content modal-content4">
              <div className="modal-content-inner">
                <img src={modalImg4} alt="" />
              </div>
              <h3>A Cattle-Station Faggot</h3>
              <p>Novel Four of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              {/* <p className="modal-content-sec-para">
                The Aussie's Spartan-Games Novel One Of The Perfect-S Novel-Series by Sean McFirnn
              </p> */}
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <AudioPlayer
                ref={audioRefs.current[5]}
                src={audio4}
                className="audio-player"
              />
              <button
                style={{
                  opacity:
                    downloadProgress.toFixed() > 0 &&
                    downloadProgress.toFixed() < 100
                      ? 0.5
                      : 1,
                }}
                disabled={
                  downloadProgress.toFixed() > 0 &&
                  downloadProgress.toFixed() < 100
                }
                className="btn-download"
                onClick={() => handleDownload(5)}
              >
                Download
              </button>
              <FontAwesomeIcon
                onClick={openModal4}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen5 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content modal-content5">
              <div className="modal-content-inner">
                <img src={modalImg5} alt="" />
              </div>
              <h3>Dan Ericson: Norwegain Cop</h3>
              <p>Novel Five of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              {/* <p className="modal-content-sec-para">
                Dan Ericson: Norwegain Cop Novel Five Of The Perfect-S Novel-Series by Sean McFirnn
              </p> */}
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <AudioPlayer
                ref={audioRefs.current[6]}
                src={audio5}
                className="audio-player"
              />
              <button
                style={{
                  opacity:
                    downloadProgress.toFixed() > 0 &&
                    downloadProgress.toFixed() < 100
                      ? 0.5
                      : 1,
                }}
                disabled={
                  downloadProgress.toFixed() > 0 &&
                  downloadProgress.toFixed() < 100
                }
                className="btn-download"
                onClick={() => handleDownload(6)}
              >
                Download
              </button>
              <FontAwesomeIcon
                onClick={openModal5}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen6 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content modal-content6">
              <div className="modal-content-inner">
                <img src={modalImg6} alt="" />
              </div>
              <h3>Mutilations</h3>
              <p>Novel Six of The Perfect-S Novel-Series</p>
              <p>Published by Sean McFirnn</p>
              {/* <p className="modal-content-sec-para">
                Dan Ericson: Norwegain Cop Novel Five Of The Perfect-S Novel-Series by Sean McFirnn
              </p> */}
              <div className="audio-liyar">
                <img src={audioLiyar} alt="" />
              </div>
              <AudioPlayer
                ref={audioRefs.current[7]}
                src={audio6}
                className="audio-player"
              />
              <button
                style={{
                  opacity:
                    downloadProgress.toFixed() > 0 &&
                    downloadProgress.toFixed() < 100
                      ? 0.5
                      : 1,
                }}
                disabled={
                  downloadProgress.toFixed() > 0 &&
                  downloadProgress.toFixed() < 100
                }
                className="btn-download"
                onClick={() => handleDownload(7)}
              >
                Download
              </button>
              <FontAwesomeIcon
                onClick={openModal6}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
        {isOpen7 && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[60] bg-black/70 backdrop-blur-sm">
            <div className="modal-content contact-us-modal modal-content1">
              <ToastContainer />
              <h3 className={"contact-us-head"}>CONTACT US</h3>
              <form onSubmit={handleSubmit}>
                <input
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  placeholder="Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <div className="Send-btn">
                  <button type="submit" className="Send-btn-inner">
                    Send
                  </button>
                </div>
              </form>
              <FontAwesomeIcon
                onClick={openModal7}
                className="circle-back"
                icon={faX}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LandingPage;
